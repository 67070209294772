export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 7,
        label: 'menuitems.projects.text',
        icon: 'ri-file-list-line',
        link: '/projects'
    },
    {
        id: 3,
        roles: [1],
        label: "menuitems.settings.text",
        icon: 'ri-settings-2-line',
        subItems: [
            {
                id: 4,
                roles: [1],
                label: 'menuitems.users.text',
                icon: 'ri-team-fill',
                link: '/users'
            },
            {
                id: 5,
                roles: [1],
                label: 'menuitems.centers.text',
                icon: 'ri-team-fill',
                link: '/centers'
            },
            {
                id: 8,
                roles: [1],
                label: 'menuitems.courses.text',
                icon: 'ri-team-fill',
                link: '/courses'
            },
            // {
            //     id: 6,
            //     roles: [1],
            //     label: 'menuitems.departments.text',
            //     icon: 'ri-team-fill',
            //     link: '/departments'
            // }
        ]
    },
]